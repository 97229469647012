import React from 'react';
import { ScreenReaderText } from '@mlc/web-ui-toolkit';
import _ from 'lodash';
import { commonClasses } from '../../components/presentational/Common/Common.elements';
import { Calculator } from '../domains/calculator.domain';
import { Member, AgePensionOtherIncome, AgePensionEstimate, OtherIncome, InvestmentMix, InvestmentOption, Contribution } from '../domains/memberData.domain';
import { IncomeType, IncomeOwnerType, RiskProfileMixType, ContactType } from '../../enums';
import { ApiQuestionnaire } from '../domains/riskProfile.domain';
import { TargetSetItem, TargetSetOption } from '../domains/targetSet.domain';
import history from '../../history';
import { formatCustomerName } from './formatter';
import moment from 'moment';
import { NpsResponse } from '../domains/npsResponse.domain';

export const DATE_FORMAT = "DD/MM/YYYY";
const mySuperAPIRCodes = ["NUL5339AU", "NUL5151AU", "NUL9928AU", "MLC0925AU", "MYSUP/GROW", "MYSUP/CONS", "MYSUP/CASH", "MYSUPER"];
const cochlearPlans = ["PSFACCUM_CHLRCAS","PSFACCUM_CHLRPER"];

export const convertToNumber = (value: any) => {
    return (parseFloat(value.replace(/[^\d.-]/g, '')));
}

// scrolls to first error and makes screenreader read it for accessibility
export const focusFirstError = () => {
    setTimeout(() => {
        let errors: any = document.getElementsByClassName(
            commonClasses.validationError
        );

        if (errors && errors.length > 0) {
            errors[0].querySelector('input')
                ? errors[0].querySelector('input').focus()
                : errors[0].focus();
        }
    }, 100)
}

// basic accessibility snippet for links
export const opensInNewWindow = (<ScreenReaderText>, opens in a new window</ScreenReaderText>);

// used to convert between api values for radio buttons since radio0 buttons can't hold booleans
const getStringFromBoolean = (value: boolean | null) => {
    if (_.isNil(value)) return '';
    return value ? 'y' : 'n'
}

// if no incomes make a mock entry to make the first age pension income input field
const getAgePensionIncome = (incomes: AgePensionOtherIncome[]) => {
    if (!incomes || incomes.length === 0) {
        return [{
            incomeType: IncomeOwnerType.Member,
            incomeAmount: 0
        }];
    } else {
        return _.map(incomes, (income) => {
            return {
                incomeType: (IncomeOwnerType as any)[income.incomeOwnerType],
                incomeAmount: income.amount,
            }
        });
    }
}

// API response to values used by formik calculator form
export const mapApiToCalculator = (member: Member): Calculator => {
    let otherEmploymentIncomeTotal = 0;
    member.otherIncomes.filter(income => income.incomeType === IncomeType.Employment_Income).forEach(filtered => otherEmploymentIncomeTotal += filtered.amount);

    let otherTaxableIncomeTotal = 0;
    member.otherIncomes.filter(income => income.incomeType === IncomeType.Taxable).forEach(filtered => otherTaxableIncomeTotal += filtered.amount);

    return {
        firstName: formatCustomerName(member.firstName),
        productName: member.productName,
        salary: member.salary.amount,
        currentSuperBalance: member.currentSuperBalance,
        projectedSuper: member.projectedSuper,
        goalSuper: member.goalSuper,
        inToolCustomIncome: member.inToolCustomIncome,
        employerContribution: member.salary.employerSuperContribution,
        insurancePremiums: member.insurancePremium,
        employerInsurancePremium: member.employerInsurancePremium,
        otherEmploymentIncome: otherEmploymentIncomeTotal,
        otherTaxableIncome: otherTaxableIncomeTotal,
        mcbCapAmount: member.mcbCapAmount,
        age: member.age,
        nearestAge: member.nearestAge,
        retirementAge: member.retirementAge || member.defaultRetirementAge,
        superAge: member.lifeExpectancy || member.defaultLifeExpectancy,
        defaultSuperAge: member.defaultLifeExpectancy,
        minAsfaAmountApplied: member.salary.minAsfaAmountApplied,
        includeAgePension: getStringFromBoolean(member.includeAgePension) || 'n',
        agePension: member.agePensionEstimate.monthlyAgePension,
        isHomeowner: getStringFromBoolean(member.agePensionEstimate.homeOwnerAtPensionAge),
        hasPartner: getStringFromBoolean(_.isNil(member.agePensionEstimate.single) ? null : !member.agePensionEstimate.single),
        financialInvestments: member.agePensionEstimate.financialAssets,
        nonFinancialAssets: member.agePensionEstimate.otherAssets,
        partnerDob: member.agePensionEstimate.partnerDateOfBirth,
        partnerIncomeAgeFrom: member.agePensionEstimate.partnerEmploymentIncomeAgeFrom,
        partnerIncomeAgeTo: member.agePensionEstimate.partnerEmploymentIncomeAgeTo,
        partnerSuperBalance: member.agePensionEstimate.partnerSuperBalance,
        partnerIncome: member.agePensionEstimate.partnerEmploymentIncome,
        agePensionOtherIncome: getAgePensionIncome(member.agePensionEstimate.otherIncomes),
        agePensionOtherIncomeCombine: getAgePensionIncome(member.agePensionEstimate.otherIncomesCombine),
        existingAccountbasedPension: member.agePensionEstimate.existingAccountbasedPension,
        otherSuper: member.otherSuper.balance,
        contributionsAmount: member.contributionAmount,
        currentContribution: member.currentContribution,
        proposedContribution: member.proposedContribution,
        investmentProfileType: member.currentMix,
        suggestedProfileType: member.riskProfileMixType,
        selectedProfileType: member.selectedMix,
        inToolCustomMix: member.inToolCustomMix,
        riskRetireIn: member.answer1ForReporting,
        riskLowRiskInvestments: member.answer2ForReporting,
        riskNegativeReturns: member.answer3ForReporting,
        riskLongTermDecline: member.answer4ForReporting,
        riskFluctuations: member.answer5ForReporting,
        maintainInvestments: member.keepExistingInvestmentMix,
        contributionAmountWarning: member.contributionAmountWarning,
        contributionWarning: member.contributionWarning,
        sgRateFromReferenceData: member.sgRateFromReferenceData,
        softCloseRiskProfileTriage: member.softCloseRiskProfileTriage,
        agePensionInfoComplete: member.agePensionInfoComplete,
        adviceRequested: member.adviceRequested,
        populateTtrValues: member.populateTtrValues,
        isNabGsfMember: member.isNabGsfMember,
        selectedMixHasMySuper: member.selectedMixHasMySuper,
        maxAccountBalance: member.maxAccountBalance,
        riskProfileTriage: member.riskProfileTriage,
        displayNps: member.displayNps,
        selectedLifestyle: member.selectedLifestyle,
        asfaComfortableReferenceAmount: member.asfaComfortableReferenceAmount,
        asfaModestReferenceAmount: member.asfaModestReferenceAmount,
        activeInvestment: member.activeInvestment,
        proposedNccCap: member.proposedNccCap
    }
}

// formik calculator form values to values used by API
export const mapCalculatorToApi = (calculator: Calculator, member: Member): Member => {
    return {
        ...member,
        firstName: calculator.firstName,
        productName: calculator.productName,
        salary: {
            ...member.salary,
            amount: calculator.salary,
            employerSuperContribution: calculator.employerContribution,
            minAsfaAmountApplied: calculator.minAsfaAmountApplied
        },
        currentSuperBalance: calculator.currentSuperBalance,
        projectedSuper: calculator.projectedSuper,
        goalSuper: calculator.goalSuper,
        insurancePremium: calculator.insurancePremiums,
        employerInsurancePremium: calculator.employerInsurancePremium,
        otherIncomes: [
            {
                ...member.otherIncomes.find(income => income.incomeType === IncomeType.Employment_Income) as OtherIncome,
                ageTo: calculator.retirementAge,
                amount: calculator.hasOtherIncome === 'y' ? calculator.otherEmploymentIncome : 0
            },
            {
                ...member.otherIncomes.find(income => income.incomeType === IncomeType.Taxable) as OtherIncome,
                ageTo: calculator.retirementAge,
                amount: calculator.hasOtherIncome === 'y' ? calculator.otherTaxableIncome : 0
            },
        ],
        age: calculator.age,
        nearestAge: calculator.nearestAge,
        retirementAge: calculator.retirementAge,
        lifeExpectancy: calculator.superAge,
        includeAgePension: !!calculator.includeAgePension ? calculator.includeAgePension === "y" : null,
        noAgePensionInfoComplete: calculator.includeAgePension === 'n',
        agePensionInfoComplete: calculator.agePensionInfoComplete,
        agePensionEstimate: {
            ...member.agePensionEstimate,
            ...convertAgePensionValues(calculator)
        },
        otherSuper: {
            ...member.otherSuper,
            balance: calculator.hasOtherSuper === 'y' ? calculator.otherSuper : 0
        },
        contributionAmount: calculator.contributionsAmount,
        currentContribution: calculator.currentContribution,
        proposedContribution: calculator.proposedContribution,
        currentMix: calculator.investmentProfileType,
        riskProfileMixType: calculator.suggestedProfileType,
        selectedMix: calculator.selectedProfileType,
        answer1ForReporting: calculator.riskRetireIn,
        answer2ForReporting: calculator.riskLowRiskInvestments,
        answer3ForReporting: calculator.riskNegativeReturns,
        answer4ForReporting: calculator.riskLongTermDecline,
        answer5ForReporting: calculator.riskFluctuations,
        keepExistingInvestmentMix: calculator.maintainInvestments,
        selectedLifestyle: calculator.selectedLifestyle,
        inToolCustomIncome: calculator.inToolCustomIncome,
        inToolCustomMix: calculator.inToolCustomMix,
        activeInvestment: calculator.activeInvestment
    }
}

export const convertQuestionnaireValues = (values: number[]): ApiQuestionnaire => {
    return {
        question1Answer: values[0],
        question2Answer: values[1],
        question3Answer: values[2],
        question4Answer: values[3],
        question5Answer: values[4]
    }
}

export const convertAgePensionValues = (values: any): AgePensionEstimate => {
    const mapToOtherIncome = (incomes: any[]) => {
        return incomes.map((income, i) => ({
            id: i + 1,
            ageFrom: 0,
            ageTo: 0,
            incomeOwnerType: income.incomeType,
            amount: income.incomeAmount
        }));
    }

    return {
        monthlyAgePension: values.agePension,
        homeOwnerAtPensionAge: values.isHomeowner ? values.isHomeowner === 'y' : null,
        single: values.hasPartner ? values.hasPartner === 'n' : null,
        partnerDateOfBirth: values.partnerDob,
        partnerEmploymentIncome: values.partnerIncome,
        partnerEmploymentIncomeAgeFrom: values.partnerIncomeAgeFrom,
        partnerEmploymentIncomeAgeTo: values.partnerIncomeAgeTo,
        partnerSuperBalance: values.partnerSuperBalance,
        otherIncomes: mapToOtherIncome(values.agePensionOtherIncome),
        otherIncomesCombine: mapToOtherIncome(values.agePensionOtherIncomeCombine),
        financialAssets: values.financialInvestments,
        otherAssets: values.nonFinancialAssets,
        existingAccountbasedPension: values.existingAccountbasedPension,
    }
}

export const isMySuperOption = (optionAPIR: string, includeAllMySup = true) => {
    return mySuperAPIRCodes.indexOf(optionAPIR) >= 0 || (includeAllMySup && optionAPIR.indexOf("MYSUP") >= 0);
}

export const isCochlearPlan = (planId: string) => {
    return cochlearPlans.indexOf(planId) >= 0;
}

const getMySuperDataForCurrentMix = (currentMix: InvestmentMix) => {
    let currentMixMySuperData = [0.0, 0];
    for (let c = 0; c < currentMix.investmentOptions.length; c++) {
        const investmentOption = currentMix.investmentOptions[c];
        if (isMySuperOption(investmentOption.option)) {
            currentMixMySuperData[0] = currentMixMySuperData[0] + investmentOption.percentage;
            currentMixMySuperData[1] = currentMixMySuperData[1] + 1;
        }
    }
    return currentMixMySuperData;
}

const areSimilarInvestmentOptions = (investmentOption: InvestmentOption, targetSetOption: TargetSetOption) => {
    return (
        !!targetSetOption &&
        investmentOption.option === targetSetOption.apir &&
        investmentOption.percentage === targetSetOption.weighting
    );
}

const areSimilarInvestmentMixes = (currentMix: InvestmentMix, currMixMySuperData: number[], targetSet: TargetSetItem) => {
    let similarOptions = [];
    let targetSetMySuperOptionCount = 0;
    let targetSetMySuperTotalPercent = 0.0;
    for (let t = 0; t < targetSet.options.length; t++) {
        let option = targetSet.options[t];
        if (!!option && isMySuperOption(option.apir)) {
            targetSetMySuperOptionCount = targetSetMySuperOptionCount + 1;
            targetSetMySuperTotalPercent = targetSetMySuperTotalPercent + option.weighting;
            continue;
        }
        let matchFoundForNonMySuper = false;
        for (let c = 0; c < currentMix.investmentOptions.length; c++) {
            let investmentOption = currentMix.investmentOptions[c];
            if (isMySuperOption(investmentOption.option)) {
                continue;
            }

            if (similarOptions.indexOf(investmentOption.option) >= 0) {
                continue;
            }

            if (areSimilarInvestmentOptions(investmentOption, option)) {
                similarOptions.push(investmentOption.option);
                matchFoundForNonMySuper = true;
                break;
            }
        }
        if (!matchFoundForNonMySuper) {
            return false;
        }
    }

    if (targetSetMySuperTotalPercent === currMixMySuperData[0]) {
        return true;
    }
    return false;
}

//hold if any target sets return contain a match for non-mysuper options and have the mysuper weighting
export const shouldHoldInvestments = (currentMix: InvestmentMix, targetSets: TargetSetItem[]) => {
    const currMixMySuperData = getMySuperDataForCurrentMix(currentMix);
    for (var i = 0; i < targetSets.length; i++) {
        if (areSimilarInvestmentMixes(currentMix, currMixMySuperData, targetSets[i])) {
            return true;
        }
    }
    return false;
}

//concats to comma/and separated string e.g. option 1, option 2 and option 3
export const getOptionListString = (targetSets: TargetSetItem[]) => {
    let optionList = [];
    const tlen = targetSets.length;
    for (let i = 0; i < tlen; i++) {
        const options = targetSets[i].options;
        const olen = options.length;
        for (let j = 0; j < olen; j++) {
            optionList.push(options[j].name);
        }
    }
    let optionListStr = "UNDEFINED";
    if (optionList.length > 1) {
        let lastOption = optionList.pop();
        optionListStr = optionList.join(", ") + " and " + lastOption;
    } else if (optionList.length === 1) {
        optionListStr = optionList.join(", ");
    }
    return optionListStr;
}

export const closedOptionExists = (investmentOptions: InvestmentOption[]) => {
    return investmentOptions.some(option => option.closed || option.hardCodedClosedOption);
}

export const errorInResponse = (data: any) => {
    if (_.isNil(data) || _.isNil(data.error)) {
        return true;
    } else {
        return !!data.error;
    }
}

export const redirectToError = (error: number) => history.push(`/${error}`);

// checks if all values that exist in both calculator and api domains are equal
export const isCalculatorApiEqual = (calculator: any, api: any) => {
    const nonApiValues = ['hasVoluntaryContributions', 'hasOtherIncome', 'hasOtherSuper'];
    let calculatorClone = _.cloneDeep(calculator);
    let apiClone = _.cloneDeep(api);

    nonApiValues.forEach(val => {
        if (!_.isNil(calculatorClone[val])) {
            delete calculatorClone[val];
        }
    });

    if (apiClone.includeAgePension === null) apiClone.includeAgePension = false;

    return _.isEqual(calculatorClone, apiClone);
}

export const validateCalculator = (values: any) => {
    const errors: any = {};
    
    if (isNaN(values.retirementAge) || (values.retirementAge < 60 && values.age < 60) || values.retirementAge > 75) {
        errors.retirementAge = 'Please enter a valid amount.';
    } else if (values.retirementAge >= values.superAge) {
        errors.retirementAge = 'Amount must be less than the age you want your super to last to.'
    } else if (values.retirementAge <= values!.age) {
        errors.retirementAge = 'Amount must be greater than your age.'
    }

    if (values.superAge < values.defaultSuperAge) {
        errors.superAge = `Amount must be greater than or equal to your default life expectancy of ${values.defaultSuperAge}.`;
    } else if (isNaN(values.superAge) || values.superAge < 70 || values.superAge > 100) {
        errors.superAge = 'Please enter a valid amount.';
    } else if (values.superAge <= values.retirementAge) {
        errors.superAge = 'Amount must be greater than your retirement age.';
    } else if (values.superAge <= values!.age) {
        errors.superAge = 'Amount must be greater than your age.';
    }

    if (isNaN(values.goalSuper.income) || values.goalSuper.income < 0 || values.goalSuper.income > 99999) {
        errors.goalSuper = true
    }

    if (isNaN(values.contributionsAmount) || values.contributionsAmount < 0 || values.contributionsAmount > 15000) {
        errors.contributionsAmount = true
    }

    if (isNaN(values.employerContribution) || values.employerContribution < values.sgRateFromReferenceData || values.employerContribution > 25) {
        errors.employerContribution = true;
    }

    if (isNaN(values.insurancePremiums) || values.insurancePremiums < 0 || values.insurancePremiums > 49999) {
        errors.insurancePremiums = true;
    }

    if (isNaN(values.employerInsurancePremium) || values.employerInsurancePremium < 0 || values.employerInsurancePremium > 10000) {
        errors.employerInsurancePremium = true;
    }

    if (values.hasOtherIncome === 'y' && (isNaN(values.otherTaxableIncome) || values.otherTaxableIncome < 0 || values.otherTaxableIncome > 250000)) {
        errors.otherTaxableIncome = true;
    }

    if ((isNaN(values.salary) || values.salary <= 0) && (values.hasOtherIncome === 'n' || (values.hasOtherIncome === 'y' && (isNaN(values.otherEmploymentIncome) || values.otherEmploymentIncome <= 0)))) {
        errors.noEmployedIncome = "Please enter any salary or other employment income you have.";
    }

    return errors;
};

export const getSuperAgeHelpText = (values: Calculator) => {
    const minAge = Math.max(values.defaultSuperAge, 70);
    return `Please enter an age between ${minAge} and 100.`
};

export const getRetirementAgeHelpText = (values: Calculator) => {
    const minAge = Math.max(values.age + 1, 60);
    return `Please enter an age between ${minAge} and 75.`
};

const riskProfileDisplay = {
    [RiskProfileMixType.Cash]: "Cash",
    [RiskProfileMixType.Conservative]: "Conservative",
    [RiskProfileMixType.Moderate]: "Moderate",
    [RiskProfileMixType.Balanced]: "Balanced",
    [RiskProfileMixType.Growth]: "Growth",
    [RiskProfileMixType.High_Growth]: "High Growth"
};

export const getProfileTypeName = (type: string) => (riskProfileDisplay as any)[type];

// focus heading of page for accessibility screenreader
export const focusHeading = () => {
    setTimeout(() => document.querySelectorAll('h1')[1]?.focus({ preventScroll: true }), 100);
}

export const isValidPartnerDob = (dob: string): boolean => {
    const momentDob = moment(dob, DATE_FORMAT);
    const yearsFromToday = moment().diff(momentDob, 'years');
    return momentDob.isValid() && (yearsFromToday >= 18 && yearsFromToday <= 100);
}

export const validateNps = (values: NpsResponse) => {
    const errors: any = {};

    if (values.rating === "") {
        errors.rating = true;
    }

    if (!values.contactType) {
        errors.contactType = true;
    }

    //this should never actually happen but putting here as a safeguard
    if (values.feedback.length > 2500) {
        errors.feedback = true;
    }

    if (values.contactType === ContactType.email) {
        if (values.email === "") {
            errors.email = "Email address is required.";
        } else if (!(/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(values.email))) {
            errors.email = "Valid email address is required."
        }
    }

    return errors;
}

export const isModalVideoOpen = () => document.getElementsByClassName('modal-video-body').length > 0;

export const toMonthlyFromFrequency = (value: number, frequency: number) => value * frequency / 12;

export const showNcc = (proposedContribution: Contribution) => proposedContribution.personal > 0.6;