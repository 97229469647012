import React from 'react';
import { Paragraph, Card } from '@mlc/web-ui-toolkit';
import { BlackTextLink } from './CalculatorSteps/CalculatorSteps.style';
import { logAction } from '../../../logic/functions/logging.function';
import NewWindowLink from '../Common/NewWindowLink';
import { plumBookingUrl, plumContactNo } from '../Common/StaticContent';
import { InvestmentMix } from '../../../logic/domains/memberData.domain';
import { asCurrency } from '../../../logic/functions/formatter';
import * as constants from '../CalculatorTab/CalculatorSteps/RiskProfile/StaticContent';
import { getProfileTypeName } from '../../../logic/functions/util';

const calculator = (closeAdvice?: any, jumpToStep?: any) => ({
    genericContactUsTriage: {
        title: "Contact us",
        content: (
            <Card>
                <Paragraph>
                    There is more than one investment option that may be right for you. This means we're unable to provide you with your results because your situation needs more tailored advice.
                </Paragraph>
                <Paragraph>
                    Our team of Financial Coaches can help with any questions you may have at no additional cost. <NewWindowLink href={plumBookingUrl} onClick={() => logAction("RESULTS_TRIAGE_BOOKING")}>Book an appointment</NewWindowLink>, or call us on {plumContactNo}.
                </Paragraph>
                <Paragraph>You should not rely on any projections or information provided by the tool and click on 'Close' to exit.</Paragraph>
            </Card>
        ),
    },
    riskProfileTriage: {
        content: (
            <Card>
                <Paragraph>
                    Based on your risk profile and available investment options, we're unable to provide you with your results.
                </Paragraph>
                <Paragraph>
                    Our team of Financial Coaches can help with any questions you may have at no additional cost. <NewWindowLink href={plumBookingUrl} onClick={() => logAction("RESULTS_TRIAGE_BOOKING")}>Book an appointment</NewWindowLink>, or call us on {plumContactNo}.
                </Paragraph>
                <Paragraph>You should not rely on any projections or information provided by the tool and click on 'Close' to exit.</Paragraph>
            </Card>
        ),
    },
    proposedContributionShortfall: {
        content: (
            <Card>
                <Paragraph>
                    Based on our projection your super balance <b>may fall short</b> of what you need to achieve your retirement goal.
                    This could be because you have entered a contribution amount that is lower than what you're currently making.
                </Paragraph>
                <Paragraph>
                    If you want to change the amount, go back to the <BlackTextLink color="black" onClick={() => {
                        logAction("RESULTS_TRIAGE_JUMP_TO_CONTS");
                        closeAdvice && closeAdvice();
                        jumpToStep && jumpToStep(4);
                        setTimeout(() => window.scroll({ top: 0, left: 0, behavior: 'smooth' }), 100);
                    }}>Contributions page.</BlackTextLink>
                </Paragraph>
                <Paragraph>
                    Otherwise, we're unable to provide you with your results because your situation needs more tailored advice.
                </Paragraph>
                <Paragraph>
                    Our team of Financial Coaches can help with any questions you may have at no additional cost. <NewWindowLink href={plumBookingUrl} onClick={() => logAction("RESULTS_TRIAGE_BOOKING")}>Book an appointment</NewWindowLink>, or call us on {plumContactNo}.
                </Paragraph>
                <Paragraph>
                    You should not rely on any projections or information provided by the tool and click on 'Close' to exit.
                </Paragraph>
            </Card>
        ),
    },
    cochlearTriage: {
        content: (
            <Card>
                <Paragraph>
                    Given your risk profile outcome and the investment options available to you, your personal situation needs further consideration that is beyond the scope of the calculator.
                </Paragraph>
                <Paragraph>
                    As a result, we are unable to offer you any advice (including a Statement of Advice) through the Personal Super Calculator. Accordingly, you should not rely on any projection or information provided by the calculator to this point. <NewWindowLink href={plumBookingUrl} onClick={() => logAction("RESULTS_TRIAGE_BOOKING")}>Book an appointment</NewWindowLink>, or call us on {plumContactNo}.
                </Paragraph>
                <Paragraph>
                    To leave the calculator, click on 'Close' below and close all windows.
                </Paragraph>
            </Card>
        ),
    },
    ageRelatedTriage: {
        content: (
            <Card>
                <Paragraph>
                    Based on your risk profile and when you plan to retire, we're unable to provide you with your results.
                    We recommend that you seek more comprehensive advice to help you put the right financial plan in place for your retirement.
                </Paragraph>
                <Paragraph>
                    Our team of Financial Coaches can help with any questions you may have at no additional cost. <NewWindowLink href={plumBookingUrl} onClick={() => logAction("RESULTS_TRIAGE_BOOKING")}>Book an appointment</NewWindowLink>, or call us on {plumContactNo}.
                </Paragraph>
                <Paragraph>
                    You should not rely on any projections or information provided by the tool and click on 'Close' to exit.
                </Paragraph>
            </Card>
        ),
    },
    ageRelatedSoftWarn: (selectedMix: InvestmentMix, riskProfileOutcome: string) => ({
        title: "Wait!",
        content: (
            <Card>
                <Paragraph>
                    You have chosen to deviate from your recommended risk profile of {getProfileTypeName(riskProfileOutcome)}. Based on the subsequent risk profile education material provided, you have confirmed that your risk profile is {getProfileTypeName(selectedMix.type!)}. An investment option in line with your selected {getProfileTypeName(selectedMix.type!)} risk profile will be recommended. {(selectedMix.buySellCost != null && selectedMix.buySellCost > 0) ? "By switching your investments, your superannuation account may incur a transaction cost of:" : ""}
                </Paragraph>
                {(selectedMix.buySellCost != null) && (selectedMix.buySellPercentage != null) && selectedMix.buySellCost > 0 && (
                    <Paragraph fontWeight="semibold">Buy-sell spread: {asCurrency(selectedMix?.buySellCost)} ({(selectedMix?.buySellPercentage).toFixed(2)}%)</Paragraph>
                )}
                <Paragraph>
                    Your selected risk profile has a minimum recommended investment timeframe of {constants.riskProfileDescription[selectedMix.type!].investmentTimeframe}. Not investing for the minimum suggested time or longer increases your chances of a negative return occurring prior to you accessing your money.
                </Paragraph>  
                <Paragraph>
                    Given your limited timeframe before retirement, you should carefully consider whether you're comfortable with the level of risk associated with switching your investment options{(selectedMix.buySellCost != null && selectedMix.buySellCost > 0) ? ", and the costs as outlined above, " : " "}before proceeding.
                </Paragraph>
                <Paragraph>
                    If you're not sure, our team of Financial Coaches can help with any questions you may have, at no additional cost. <NewWindowLink href={plumBookingUrl} onClick={() => logAction("RESULTS_TRIAGE_BOOKING")}>Book an appointment</NewWindowLink>
                </Paragraph>
            </Card>
        ),
    }),
    sameRpIncreasedFees: {
        content: (
            <Card>
                <Paragraph>
                    Given your risk profile outcome and the available investment options available to you, your personal situation needs further consideration that is beyond the scope of the calculator.
                </Paragraph>
                <Paragraph>
                    As a result, we are unable to offer you any advice (including a Statement of Advice) through the Personal Super Calculator. Accordingly, you should not rely on any projection or information provided by the calculator to this point. Contact us on {plumContactNo}.
                </Paragraph>
                <Paragraph>
                    To leave the calculator, click on 'Close' below and close all windows.
                </Paragraph>
            </Card>
        ),
    },
    memberInsPremsDecreasedBalance: {
        title: "Contact us",
        content: (
            <Card>
                <Paragraph>
                    Due to your insurance premiums significantly reducing your super balance at retirement, your personal situation needs further consideration that is beyond the scope of the calculator.
                </Paragraph>
                <Paragraph>
                    As a result, we are unable to offer you any advice (including a Statement of Advice) through the Personal Super Calculator.
                </Paragraph>
                <Paragraph>
                    Our team of Financial Coaches can help with any questions you may have at no additional cost. <NewWindowLink href={plumBookingUrl} onClick={() => logAction("RESULTS_TRIAGE_BOOKING")}>Book an appointment</NewWindowLink>, or call us on {plumContactNo}.
                </Paragraph>
                <Paragraph>You should not rely on any projections or information provided by the tool and click on 'Close' to exit.</Paragraph>
            </Card>
        ),
    },
})

export default calculator;