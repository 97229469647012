import { Text } from '@mlc/web-ui-toolkit';
import React from 'react';
import commonTestIds from './Common.elements';

export const agePensionInfo = <Text data-id={commonTestIds.speedometerAgePensionInfo}>The <Text fontWeight="semibold">"Age Pension"</Text> amount is an estimate and not a guarantee.</Text>;
export const youMayHaveInfo = (
    <Text role="text" data-id={commonTestIds.speedometerReceivedInfo}>
        <Text fontWeight="semibold">"You may receive"</Text> is based on a calculation which considers:
        <ul>
            <li>your current super balance</li>
            <li>your projected super balance</li>
            <li>current superannuation contributions made by you and your employer</li>
            <li>retirement age, income and expected age you want your super to last to as chosen in Step 2 of 7</li>
            <li>projected return, which is based on your current asset allocation in your super account, and</li>
            <li>any inputs provided by you in this calculator.</li>
        </ul>
        Note: The calculation is a projection only and not a guarantee, what you receive may differ based on actual returns, contributions made and actual retirement age.
    </Text>
)

export const youMayNeedInfo = (
    <Text role="text" data-id={commonTestIds.speedometerNeededInfo}>
        <Text fontWeight="semibold">"You may need"</Text> is based on the retirement income selected in Step 2 of 7.
    </Text>
)

export const toolDate = "1 April 2025";

//monthly values (99999 and 15000) * 12
export const customAnnualRetirementIncomeError = 1199988;
export const customAnnualContributionsError = 180000;
export const mlcBookingUrl = "https://mlc.com.au/bookacoach";
export const mlcContactNo = "1800 111 171";
export const plumBookingUrl = "https://plum.com.au/bookacoach";
export const plumContactNo = "1800 903 323";
