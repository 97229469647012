import React, { useState, useEffect } from "react";
import { LoadingIndicator, ScreenReaderText } from '@mlc/web-ui-toolkit';
import { NavBarStyle, NavHeader } from './NavBar.style';
import * as palette from '@mlc/mlc-palette';
import ImportantInfoModal from "../ImportantInfo/ImportantInfoModal";
import CalculatorTab from "../CalculatorTab";
import SuperReport from "../SuperReport";
import Tab from "../Common/Tab";
import TakeAction from "../TakeAction";
import smoothscroll from 'smoothscroll-polyfill';
import AnalyticsForm from "../Common/AnalyticsForm";
import { Member } from "../../../logic/domains/memberData.domain";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { loadMemberData } from "../../../logic/functions/memberData.function";
import NPS from "./NPS";
import StepProgressBar from "./StepProgressBar";
import { FrequencyValues } from "../../../logic/domains/calculator.domain";
import commonTestIds from "../Common/Common.elements";


type NavBarProps = {
    activeStep?: number;
}

type Props = NavBarProps & RouteComponentProps;

// local state to hold some values not set/sent by API
type State = {
    numberOfSteps: number;
    activeStepIndex: number;
    tAndCAccepted: any;
    memberData?: Member;
    localCalculatorValues: any;
    frequencyValues: FrequencyValues;
};

const NavBar = ({ activeStep, location }: Props) => {
    const locationState: any = location.state;
    smoothscroll.polyfill();
    const activeStepIndex = activeStep || 0;
    const [loading, setLoading] = useState(true);
    const [state, setState] = React.useState<State>({
        numberOfSteps: 8,
        activeStepIndex: activeStepIndex,
        tAndCAccepted: {
            tAndC: false,
            fsg: false
        },
        memberData: locationState ? locationState.memberData : undefined,
        localCalculatorValues: locationState ? locationState.localCalculatorValues: {
            hasOtherIncome: 'n',
            hasOtherSuper: 'n'
        },
        //For maintaining frequency on different steps. 1 = Annually, 12 = Monthly, 26 = Fortnightly and 52 = Weekly
        frequencyValues: {
            retirementGoals: {value: 1, label: "annually"}, 
            regularContributions: {value: 12, label: "monthly"}
        }
    });

    const [isNpsOpen, setIsNpsOpen] = useState(false);
    const [shouldDisplayNps, setShouldDisplayNps] = useState(sessionStorage.getItem("npsDone") == null);
    const [forceCloseNps, setForceCloseNps] = useState(false);

    const stepNames = ["About you", "About you", "Retirement goals", "Risk profile", "Regular contributions", "Retirement projection", "Super report", "Take action"];

    useEffect(() => {
        const fetchData = async () => {
            if (!state.memberData) {
                const apiValues = await loadMemberData();
                setState({
                    ...state,
                    memberData: apiValues,
                    localCalculatorValues: {
                        hasOtherIncome: apiValues.otherIncomes.some((income: any) => income.amount > 0) ? 'y' : 'n',
                        hasOtherSuper: apiValues.otherSuper.balance > 0 ? 'y' : 'n',
                    }
                });
                setShouldDisplayNps(sessionStorage.getItem("npsDone") == null && apiValues.displayNps);
            }
            setLoading(false);
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const scrollToTop = () => {
        setTimeout(() => window.scroll({ top: 0, left: 0, behavior: 'smooth' }), 100);
    }

    const completeForm = () => {
        (window as any).digitalData.event.push({
            eventType: "spa-form-interacted",
            data: {
                action: "spa-form-completed",
                name: "Personal Super Calculator",
                page: "Success",
            },
        });
        //@ts-ignore
        $(document).trigger('spa-form-interacted');
    }

    const handleNextStep = () => {
        const newActiveStepIndex = state.activeStepIndex + 1;
        // don't go beyond last step
        if (newActiveStepIndex < state.numberOfSteps) {
            setState(() => {
                return {
                    ...state,
                    activeStepIndex: newActiveStepIndex,
                }
            });
            scrollToTop();
            if (newActiveStepIndex === state.numberOfSteps - 2) {
                completeForm();
            }
        }
    }

    const handlePreviousStep = () => {
        const newActiveStepIndex = state.activeStepIndex - 1;
        // don't go beyond first step
        if (newActiveStepIndex >= 0) {
            setState(() => {
                return {
                    ...state,
                    activeStepIndex: newActiveStepIndex,
                }
            });
            scrollToTop();
        }
    }

    const jumpToStep = (index: number) => {
        setState(() => {
            return {
                ...state,
                activeStepIndex: index
            };
        });
    }

    const setAccepted = (val: any) => {
        setState(prevState => {
            return {
                ...prevState,
                tAndCAccepted: {
                    ...prevState.tAndCAccepted,
                    ...val
                }
            }
        })
    }

    const setLocalCalculatorValues = (val: any) => {
        setState(prevState => {
            return {
                ...prevState,
                localCalculatorValues: {
                    ...prevState.localCalculatorValues,
                    ...val
                }
            }
        })
    }

    const getRedirectPath = (pathname: string) => {
        return (
            <Redirect to={{
                pathname: pathname,
                state: state
            }} />
        )
    }

    // changes url to checkpoints in router
    if (state.activeStepIndex === 1 && location.pathname !== '/calculator') {
        return getRedirectPath('/calculator');
    } else if (state.activeStepIndex === 6 && location.pathname !== '/advice') {
        return getRedirectPath('/advice');
    } else if (state.activeStepIndex === 7 && location.pathname !== '/nextsteps') {
        return getRedirectPath('/nextsteps');
    }

    return (
        <NavBarStyle>
            <StepProgressBar progress={(100/6)*(state.activeStepIndex-1)} length={state.numberOfSteps-1} activeStep={state.activeStepIndex}/>
            <NavHeader color={palette.mlcWhite} pageName={stepNames[state.activeStepIndex]} pageDescription={<ScreenReaderText>, step {state.activeStepIndex + 1} of {state.numberOfSteps}</ScreenReaderText>} data-id={commonTestIds.navBarHeader} />
            <LoadingIndicator isOpen={loading} message="Just a moment..." variant="fullscreen" />
            {!loading && (
                <>
                    {/* analytics script errors and can cause UI to freeze (at least locally) if the below form isn't there */}
                    {state.activeStepIndex === 0 && <AnalyticsForm stepIndex={0}/>}
                    {[0, 1, 2, 3, 4, 5].indexOf(state.activeStepIndex) > -1 && (
                        <Tab containInGrid={false}>
                            <ImportantInfoModal
                               accepted={state.tAndCAccepted}
                               productName={state.memberData!.productName}
                               setAccepted={setAccepted} 
                               modalOpen={state.activeStepIndex === 0}
                               handleNextStep={handleNextStep}
                               />
                            <CalculatorTab
                                handleNextStep={handleNextStep}
                                handlePreviousStep={handlePreviousStep}
                                memberData={state.memberData}
                                setMemberData={(values: Member) => setState({ ...state, memberData: values })}
                                stepIndex={state.activeStepIndex}
                                jumpToStep={jumpToStep}
                                localCalculatorValues={state.localCalculatorValues}
                                setLocalCalculatorValues={setLocalCalculatorValues}
                                frequencyValues={state.frequencyValues}
                            />
                        </Tab>
                    )}

                    {state.activeStepIndex === 6 && (
                        <AnalyticsForm stepIndex={state.activeStepIndex}>
                            <Tab containInGrid={false}>
                                <SuperReport setForceCloseNps={setForceCloseNps} handlePreviousStep={handlePreviousStep} handleNextStep={handleNextStep} memberData={state.memberData} setIsNpsOpen={setIsNpsOpen} shouldDisplayNps={shouldDisplayNps} />
                            </Tab>
                        </AnalyticsForm>
                    )}

                    {state.activeStepIndex === 7 && (
                        <AnalyticsForm stepIndex={state.activeStepIndex}>
                            <Tab containInGrid={false}>
                                <TakeAction handlePreviousStep={handlePreviousStep} memberData={state.memberData} frequencyValues={state.frequencyValues} />
                            </Tab>
                        </AnalyticsForm>
                    )}
                    <NPS isOpen={isNpsOpen} setIsOpen={setIsNpsOpen} setShouldDisplayNps={setShouldDisplayNps} forceCloseNps={forceCloseNps} name={state.memberData?.firstName!}/>
                </>
            )}
        </NavBarStyle>
    );
}

export default NavBar;