import { externalLink } from "@mlc/symbols";
import { Card, Grid, Heading, Paragraph, Svg, Text, TextLink } from "@mlc/web-ui-toolkit";
import classNames from "classnames";
import _ from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AppThemeContext } from "../../../../../context/AppThemeContext";
import { Calculator, FrequencyValues } from "../../../../../logic/domains/calculator.domain";
import { asDecimalCurrency, asRoundedCurrency, currencyToInt } from "../../../../../logic/functions/formatter";
import { logAction } from "../../../../../logic/functions/logging.function";
import { convertToNumber, focusHeading, showNcc, toMonthlyFromFrequency, validateCalculator } from "../../../../../logic/functions/util";
import { commonClasses } from "../../../Common/Common.elements";
import { StyledModal } from "../../../Common/Common.style";
import FieldWithTooltip from "../../../Common/FieldWithTooltip";
import InfoPopup from "../../../Common/InfoPopup";
import { MobileDeviceQ } from "../../../Common/MediaQ";
import NewWindowLink from "../../../Common/NewWindowLink";
import Select from "../../../Common/Select";
import { customAnnualContributionsError } from "../../../Common/StaticContent";
import { TooltipTextlink } from "../../AboutYou/AboutYou.style";
import { ContributionImageWithText, ContributionsHeading, ContributionsInput, ContributionsWarningCard, ExtendedCard, RegularContributionsSectionContainer, ContributionsHeadingContent, RegularContributionsInputs, RegularContributionsWrapper, RegularContributionsAccordion } from "../CalculatorSteps.style";
import regularContributionsTestIds from "./RegularContributions.elements";
import RegularContributionsTable from "./RegularContributionsTable";

type Props = {
    formikProps: any;
    setUpdatingMember: () => void;
    jumpToStep: (index: number) => void;
    setErrors: (newErrors: any) => void;
    frequencyValues: FrequencyValues;
    proposedMaxCcCont: number;
}

//calculator step 5 of 8
const RegularContributions = ({ formikProps, setUpdatingMember, jumpToStep, setErrors, frequencyValues, proposedMaxCcCont }: Props) => {
    const { setFieldValue } = formikProps;
    const values: Calculator = formikProps.values;
    const isMlc = useContext(AppThemeContext).isMlc;
    const
        {
            contributionsAmount,
            employerInsurancePremium,
            productName,
            currentContribution,
            proposedContribution,
            contributionAmountWarning,
            adviceRequested,
            populateTtrValues,
            proposedNccCap
        } = values;
    const [isDefinitionsOpen, setIsDefinitionsOpen] = useState(false);
    const definitionsRef = useRef<any>();
    const [selectedFrequency, setSelectedFrequency] = useState(frequencyValues.regularContributions);
    // stores a monthly value as backend uses monthly
    const [inputContributions, setInputContributions] = useState(contributionsAmount);
    const [inputErrors, setInputErrors] = useState<any>({});
    const content = useContext(AppThemeContext).staticContent.regularContributions(productName);
    const [showTooltip, setShowTooltip] = useState(false);
    const [biggestBoostAccordionOpen, setBiggestBoostAccordionOpen] = useState(false);
    const [contsTableAccordionOpen, setContsTableAccordionOpen] = useState(false);
    const [contsInputChanged, setContsInputChanged] = useState(false);
    const [contsChangedValue, setContsChangedValue] = useState("");

    useEffect(() => {
        focusHeading();
    }, []);

    useEffect(() => {
        // update after changing inputs but only if no inline errors
        const errors = validateCalculator({ ...values, contributionsAmount: inputContributions });
        setInputErrors(errors);
        setErrors(errors);
        if (_.isNil(errors.contributionsAmount) && !_.isEqual(values.contributionsAmount, inputContributions)) {
            setFieldValue(setFieldValue("contributionsAmount", inputContributions));
            setUpdatingMember();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputContributions]);

    useEffect(() => {
        setInputContributions(contributionsAmount);
        if ((values.proposedContribution.superGuarantee + values.proposedContribution.salarySacrifice) >= proposedMaxCcCont && values.proposedContribution.incomeTax > 0) {
            setShowTooltip(true);
        }
        else setShowTooltip(false);
    }, [contributionsAmount, values, proposedMaxCcCont]);

    const closeDefinitions = () => {
        setIsDefinitionsOpen(false);
        setTimeout(() => {
            definitionsRef.current && definitionsRef.current!.focus();
        }, 100);
    }

    const budgetPlannerLink = (
        <Text display="block" marginBottom="15px">
            Not sure?{" "}
            <NewWindowLink
                href="https://moneysmart.gov.au/budgeting/budget-planner"
                data-id={regularContributionsTestIds.budgetPlanner}
                onClick={() => logAction("LINK_BUDGET_CALC")}
            >
                Try ASIC's Budget planner <Svg width="12px" symbol={externalLink} />
            </NewWindowLink>
            .
        </Text>
    );

    const getContributionsWarning = () => {
        if (employerInsurancePremium > 0 || contributionAmountWarning) {
            const eppWarning = (
                <Paragraph data-id={regularContributionsTestIds.employerPremiumsWarning}>
                    The premium your employer pays is treated as a concessional contribution and is counted towards your concessional contribution cap. To
                    review your inputs go to{' '}
                    <TextLink
                        href="#"
                        onClick={(e: any) => {
                            e.preventDefault();
                            logAction("CONTS_JUMP_TO_UPDATE_DETAILS");
                            jumpToStep(1);
                        }}
                        data-id={regularContributionsTestIds.warningUpdateDetails}
                    >
                        Edit your details
                    </TextLink>.
                </Paragraph>
            );

            let warning;

            if (employerInsurancePremium > 0 && !contributionAmountWarning) {
                warning = eppWarning;
            } else if (contributionAmountWarning) {
                let contrWarning;
                if (currencyToInt(asRoundedCurrency(proposedContribution.totalTakeHomePay)) <= 1) {
                    contrWarning = (
                        <Card data-id={regularContributionsTestIds.reducedWarningTHP}>
                            Your proposed contributions have been reduced because the calculator will not allow you to contribute more than your take home pay.
                        </Card>
                    );
                } else if (currencyToInt(asRoundedCurrency(proposedContribution.personal)) === proposedNccCap) {
                    contrWarning = (
                        <Card data-id={regularContributionsTestIds.reducedWarningNCC}>
                            Your proposed contributions have been reduced because the calculator will not allow you to contribute more than the current annual general contribution caps.
                        </Card>
                    );
                }
                if (employerInsurancePremium > 0) {
                    warning = (
                        <>
                            {eppWarning}
                            {contrWarning}
                        </>
                    );
                } else if (employerInsurancePremium === 0) {
                    warning = contrWarning;
                }
            }

            return (
                <ContributionsWarningCard id={regularContributionsTestIds.proposedContributionsWarning} data-id={regularContributionsTestIds.proposedContributionsWarning}>
                    {warning}
                </ContributionsWarningCard>
            )
        } else {
            return null;
        }
    }

    return (
        <>
            <ExtendedCard className="with-background">
                <Grid>
                    <MobileDeviceQ
                    content={
                    <ContributionsHeading>
                        <ContributionsHeadingContent>
                            <Heading paddingBottom="5px" el="h2" fontWeight="semibold" fontSize="large">Did you know you can turn a $3.80 cup of coffee a day into an extra $45,066 at retirement?
                                <InfoPopup label="Information about coffee example"
                                    content={<Text><b>How a cup of coffee can give you an extra $45,066 at retirement.</b> Example used is from the Small Change, Big Savings calculator on superguru.com.au. Figures are based on a 37 year old investing the cost of a coffee ($3.80) as a monthly amount into a balanced fund and assuming 4.8% growth over a 30 year period. Visit <TooltipTextlink target="_blank" rel="noopener" href="https://www.superguru.com.au/ExternalFiles/calculators/small-change/index.html" data-id={regularContributionsTestIds.coffeeTooltip} onClick={() => logAction("MONTHLY_CONT_COFFEE_LINK")}>https://www.superguru.com.au/ExternalFiles/calculators/small-change/index.html</TooltipTextlink> for a full list of assumptions.</Text>}
                                    log="MONTHLY_CONT_COFFEE_TOOLTIP"
                                />
                            </Heading>
                            <Text paddingBottom="10px">
                                Even small contributions can snowball over time, so the earlier you start the bigger your retirement savings could become.
                            </Text>
                        </ContributionsHeadingContent>
                        <img alt="" width="100px" src={content.coffee} />
                    </ContributionsHeading>
                    }
                    mobileContent={
                        <ContributionsHeading>
                            <ContributionsHeadingContent>
                                <Heading paddingBottom="5px" el="h2" fontWeight="semibold" fontSize="large">Did you know you can turn a $3.80 cup of coffee a day into an extra $45,066 at retirement?
                                    <InfoPopup label="Information about coffee example"
                                        content={<Text><b>How a cup of coffee can give you an extra $45,066 at retirement.</b> Example used is from the Small Change, Big Savings calculator on superguru.com.au. Figures are based on a 37 year old investing the cost of a coffee ($3.80) as a monthly amount into a balanced fund and assuming 4.8% growth over a 30 year period. Visit <TooltipTextlink target="_blank" rel="noopener" href="https://www.superguru.com.au/ExternalFiles/calculators/small-change/index.html" data-id={regularContributionsTestIds.coffeeTooltip} onClick={() => logAction("MONTHLY_CONT_COFFEE_LINK")}>https://www.superguru.com.au/ExternalFiles/calculators/small-change/index.html</TooltipTextlink> for a full list of assumptions.</Text>}
                                        log="MONTHLY_CONT_COFFEE_TOOLTIP"
                                    />
                                </Heading>
                                <img alt="" src={content.coffee} />
                            </ContributionsHeadingContent>
                            <Text paddingBottom="10px">
                                    Even small contributions can snowball over time, so the earlier you start the bigger your retirement savings could become.
                            </Text>
                        </ContributionsHeading>
                    }
                    />
                </Grid>
            </ExtendedCard>
            <RegularContributionsSectionContainer>
                <FieldWithTooltip
                    label={`How much can you afford to contribute to your ${isMlc ? `${values.productName} account` : "Plum Super account"}?`}
                    id={regularContributionsTestIds.contributionsInputFieldWrapper}
                    tooltipContent="This is the total amount you can afford to contribute from your take home pay after meeting all your living expenses. It should not include any current voluntary contributions."
                    tooltipId={regularContributionsTestIds.contributionsInfo}
                    tooltipLabel="Information about super contributions"
                    tooltipLog="VOLUNTARY_CONT_MONTH_TT"
                    error={<>
                        Please enter an amount between $0 and {asRoundedCurrency(customAnnualContributionsError / selectedFrequency.value)}.
                    </>}
                    isInvalid={inputErrors.contributionsAmount}
                ><RegularContributionsWrapper>
                        <RegularContributionsInputs>
                            <Text id="contribution-label" fontWeight="medium">Contribution</Text>
                            <Text id="frequency-label" fontWeight="medium">Frequency</Text>
                        </RegularContributionsInputs>
                        <RegularContributionsInputs className="contributions">
                            <ContributionsInput
                                prefix="$"
                                value={contsInputChanged ? contsChangedValue : Math.round(inputContributions * 12 / selectedFrequency.value).toString()}
                                onChange={(e: any) => {
                                   setContsInputChanged(true);
                                   setContsChangedValue(e.target.value);
                                }}
                                onBlur={(e: any) => {
                                    if (contsInputChanged) {
                                        setInputContributions(toMonthlyFromFrequency(convertToNumber(e.target.value), selectedFrequency.value));
                                        setContsInputChanged(false);
                                    }
                                }}
                                onKeyDown={(e: any) => {
                                    if (e.keyCode === 13 || e.key === 'Enter') {
                                        e.preventDefault();
                                        if (contsInputChanged) {
                                            setInputContributions(toMonthlyFromFrequency(convertToNumber(e.target.value), selectedFrequency.value));
                                            setContsInputChanged(false);
                                        }
                                    }
                                }}
                                allowDecimal={false}
                                allowLeadingZeroes={true}
                                integerLimit={9}
                                aria-describedby={`${contributionAmountWarning ? `${regularContributionsTestIds.proposedContributionsWarning}` : ""} contribution-input-error`}
                                aria-invalid={inputErrors.contributionsAmount}
                                aria-labelledby="contribution-label"
                                className={inputErrors.contributionsAmount ? commonClasses.validationError : ''}
                                data-analytics-capture-value
                                data-id={regularContributionsTestIds.contributionsInput}
                            />
                            <Select
                                onChange={(e: any) => {
                                    const newFreqValue = e.target.value;
                                    const newFreqLabel = e.target.options[e.target.selectedIndex].text;
                                    setSelectedFrequency({ value: newFreqValue, label: newFreqLabel.toLowerCase() });
                                    frequencyValues.regularContributions = { value: newFreqValue, label: newFreqLabel.toLowerCase() };
                                    logAction(`CONTRIBUTIONS_FREQUENCY_${newFreqLabel}`);
                                }}
                                aria-labelledby="frequency-label"
                                value={frequencyValues.regularContributions.value}
                                options={[{ value: 1, label: "Annually" }, { value: 12, label: "Monthly" }, { value: 26, label: "Fortnightly" }, { value: 52, label: "Weekly" }]} 
                                data-id={regularContributionsTestIds.contributionsFrequency}/>
                        </RegularContributionsInputs>
                    </RegularContributionsWrapper>
                </FieldWithTooltip>
                {getContributionsWarning()}
                {budgetPlannerLink}
                <RegularContributionsAccordion title={<Card paddingTop="5px" paddingBottom="5px"><Text fontSize="18px" fontWeight="semibold" el="h2">How to get the biggest boost to your super from this contribution</Text></Card>} isOpen={biggestBoostAccordionOpen} onChange={() => { setBiggestBoostAccordionOpen(!biggestBoostAccordionOpen); logAction("BIGGEST_BOOST_ACCORDION"); }} data-id={regularContributionsTestIds.biggestBoostAccordion}  className={classNames(biggestBoostAccordionOpen? "open": "")}>
                    <ContributionImageWithText>
                        <img alt="" src={content.piggyBank} />
                        <Card display="inline">
                            <ul>
                                <li>
                                    <div>
                                        <Text fontWeight="semibold">Make before tax (concessional) contributions of {asDecimalCurrency(adviceRequested ? proposedContribution.salarySacrifice / frequencyValues.regularContributions.value : 0)} {selectedFrequency.label} into your { isMlc ? `${values.productName} account` : "Plum Super account" }.</Text>
                                        {showTooltip ? <InfoPopup label="Less voluntary concessional contributions (before tax)" content="You've reached the concessional contributions cap for this financial year. Contributions above the limit may be taxed at your marginal rate, with a 15% offset for contributions tax. Keep track of your contributions to avoid exceeding the cap." log="CC_CONT_TOOLTIP" /> : ''}
                                    </div>
                                    <Text display="block" marginTop="15px">This is based on what you told us you can afford and has been converted to a pre-tax amount. This would reduce your take home pay by {asRoundedCurrency(values.currentContribution.totalTakeHomePay - (proposedContribution.totalTakeHomePay + (!showNcc(values.proposedContribution) ? values.proposedContribution.personal : 0)))} pa.</Text>
                                    <Text display="block" marginBottom="15px">These concessional contributions would also reduce your income tax payable by approximately {asRoundedCurrency(values.currentContribution.incomeTax - values.proposedContribution.incomeTax)} pa.</Text>
                                </li>
                                <li><Text fontWeight="semibold">Make after tax (non-concessional) contributions of {asDecimalCurrency((adviceRequested && showNcc(values.proposedContribution)) ? proposedContribution.personal / frequencyValues.regularContributions.value : 0)} {selectedFrequency.label} into your { isMlc ? `${values.productName} account` : "Plum Super account" }.</Text></li>
                            </ul>
                        </Card>
                    </ContributionImageWithText>
                </RegularContributionsAccordion>
                <RegularContributionsAccordion title={<Card paddingTop="5px" paddingBottom="5px"><Text fontSize="18px" fontWeight="semibold" el="h2">Explore how this contribution might affect your tax and take home pay annually</Text></Card>} isOpen={contsTableAccordionOpen} onChange={() => { setContsTableAccordionOpen(!contsTableAccordionOpen); logAction("CONTS_TABLE_ACCORDION"); }} data-id={regularContributionsTestIds.contributionsTableAccordion} className={classNames(contsTableAccordionOpen? "open": "")}>
                    <RegularContributionsTable
                        currentContribution={currentContribution}
                        proposedContribution={proposedContribution}
                        setIsDefinitionsOpen={() => setIsDefinitionsOpen(true)}
                        definitionsRef={definitionsRef}
                        productName={productName}
                        showProposedValues={adviceRequested || populateTtrValues}
                        showTooltip={showTooltip}
                    />
                </RegularContributionsAccordion>
            </RegularContributionsSectionContainer>
            <StyledModal
                isOpen={isDefinitionsOpen}
                onRequestClose={closeDefinitions}
                title="Definitions"
                align="left"
                footerButtons={[
                    {
                        onClick: closeDefinitions,
                        content: "Ok"
                    }
                ]}
                data-id={regularContributionsTestIds.termDefintionsModal}
                shouldCloseOnOverlayClick={true}
            >
                <>
                    <Card marginBottom={6}>
                        <Heading el="h2" fontSize="tiny">Taxable income</Heading>
                        <Text>Taxable income is your assessable income for tax purposes minus all tax deductions allowed.</Text>
                    </Card>
                    <Card marginBottom={6}>
                        <Heading el="h2" fontSize="tiny">Voluntary concessional contributions into super</Heading>
                        <Text>Voluntary concessional contributions can generally be either via salary sacrifice through your employer or contributions you claim as an income tax deduction. The amount you contribute will generally be taxed at the concessional rate of 15% instead of your marginal tax rate. Individuals with income from certain sources above $250,000 will pay an additional 15% tax on salary sacrifice and other concessional super contributions within the concessional cap. Before making voluntary contributions, you should consider the overall implications on your tax position and whether your lower take home income will cover all your expenses. Voluntary concessional contributions count towards your concessional contribution cap.</Text>
                    </Card>
                    <Card marginBottom={6}>
                        <Heading el="h2" fontSize="tiny">After-tax personal contributions</Heading>
                        <Text>After tax personal contributions or voluntary contributions are made from your after tax income into your super fund. These are not subject to any additional tax when they enter or leave the fund (because you have already paid tax on these amounts).</Text>
                    </Card>
                    <Card marginBottom={6}>
                        <Heading el="h2" fontSize="tiny">Take home pay</Heading>
                        <Text>Please note that this calculation does not take into consideration the changes to your take home pay due to contributions to other super funds.</Text>
                    </Card>
                    <Card marginBottom={6}>
                        <Heading el="h2" fontSize="tiny">Employer contributions</Heading>
                        <Text>Employer contributions include:</Text>
                        <ul>
                            <li>Superannuation Guarantee (SG) contributions which are compulsory and generally the minimum amount that your employer must put into your super fund.</li>
                            <li>any contributions paid by your employer which is more than the minimum SG contribution amount.</li>
                            <li>any superannuation fees and costs paid for you by your employer, for example any administration fees and insurance premiums.</li>
                        </ul>
                    </Card>
                    {(currentContribution.govCoCotribution > 0 || proposedContribution.govCoCotribution > 0) && (
                        <Card marginBottom={6}>
                            <Heading el="h2" fontSize="tiny">Government co-contribution</Heading>
                            <Paragraph>The superannuation (super) co-contribution is a government initiative to help eligible individuals boost their super savings for the future.</Paragraph>
                            <Paragraph>If you're a low-income earner, after-tax contributions may also attract a super co-contribution from the Government. Certain conditions must be met to be eligible for Government co-contribution including income levels, age and lodging a tax return.</Paragraph>
                        </Card>
                    )}
                    {(currentContribution.lowIncomeSuperContribution > 0 || proposedContribution.lowIncomeSuperContribution > 0) && (
                        <Card marginBottom={6}>
                            <Heading el="h2" fontSize="tiny">Low income super tax offset</Heading>
                            <Text>The low income super tax offset will provide a contribution into the super fund of up to $500 annually for individuals with an adjusted taxable income that does not exceed $37,000. This payment represents a refund of tax payable on concessional contributions to a maximum of $500.</Text>
                        </Card>
                    )}
                </>
            </StyledModal>
        </>
    )
};

export default RegularContributions;
